._recrutation {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
}

._type-secret-code {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__title {
        text-transform: uppercase;
    }

    &__field {
        margin: 1rem 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &-input {
            font-size: 2rem;
            padding: 0.5rem 1rem;
            text-align: center;
            width: 30rem;
            @include ff-text;
        }
    }

    &__check {
        display: flex;
        justify-content: center;
        align-items: center;

        &-button {
            font-size: 1.6rem;
            @include button;
            width: 20rem;
            padding: 1rem;
        }
    }

    &__recrutation-name {
        padding: 1rem 1rem 2rem 1rem;
        font-size: 2rem;
        font-weight: bold;
    }
}

._candidate-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__recrutation-name {
        padding: 1rem 1rem 2rem 1rem;
        font-size: 2rem;
        font-weight: bold;
    }

    &__title {
        font-size: 2rem;
        text-align: center;
        text-transform: uppercase;
        padding: 1rem;
    }

    &__state {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        &-button {
            @include button;
            padding: 1rem;
            width: 20rem;
        }
    }
}

._question {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 500px;
    padding: 1rem;

    &__timer,
    &__timer-global {
        width: 100%;
        text-align: center;
        padding: 0 0 1rem 0;
    }

    &__title {
        width: 100%;
        text-align: center;
        padding: 2rem 0 1rem 0;
    }

    &__question {
        width: 100%;
        text-align: center;
        padding: 1rem 0;
        border: 1px solid #bbb;
        border-radius: 0.5rem 0.5rem 0 0;
    }

    &__answers {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0.5rem 1rem;
        align-items: stretch;
        border: 1px solid #bbb;
        border-top: none;
        border-radius: 0 0 0.5rem 0.5rem;
    }

    &__answer {
        display: flex;
        border: 1px solid $color-primary;
        margin: 0.5rem 0;
        align-items: center;
        border-radius: 0.5rem;
        cursor: pointer;
        padding: 0.5rem;

        &-icon {
            height: 2rem;
            border: 2px solid $color-primary;
            border-radius: 1rem;
            margin: 0.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 2rem 0 0;

            @include ff-icon;
            color: $color-primary;
        }

        &-text {
            padding: 0.5rem;
            flex: 1 0 10rem;
        }

        &.is-check {
            ._question__answer {
                &-icon {
                    background-color: $color-primary;
                    color: $color-primary-c;
                }
            }
        }
    }

    &__buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
    }

    &__button {
        width: 20rem;
        @include button;
        padding: 1rem;
        cursor: pointer;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(#eee, 0.95);
    font-size: 1.6rem;

    &__title {
        text-transform: uppercase;
        font-size: 2rem;
        max-width: 500px;
        padding: 1rem;
        text-align: center;
    }

    &__description {
        max-width: 500px;
        padding: 1rem;
    }

    &__children {
        max-width: 500px;
        padding: 1rem;
        display: flex;
        flex-direction: column;

        &-button {
            width: 200px;
            padding: 1rem;
            @include button;
        }
    }
}
