html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-size: 10px;
    min-width: 320px;
}

body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 10px;
    width: 100%;
    height: 100%;
    min-width: 320px;
    background-color: $color-background;

    @include ff-text;
}

#root {
    position: relative;
    width: 100%;
    min-height: 100%;
    // height: 100%;
    display: block;
}

.router {
    position: relative;
    width: 100%;
}

* {
    box-sizing: border-box;

    &:focus {
        outline: none;
    }
}

img {
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
}

.app {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
